/* eslint-disable */
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../CustomButtons/Button.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      maxHeight: "70vh",
      overflowY: "auto",
    },
  },
  title: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
}));

export default function BetweenQuestionDialog({ name, answers, handleAnswer }) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = useState("");
  const pickAnswer = (event) => {
    setValue(event.target.value);
  };

  const submitForm = (event) => {
    event.preventDefault();
    handleAnswer(value);
  };

  const classes = useStyles();

  return (
      <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-content"
      >
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h6" className={classes.title} gutterBottom>
            {name}
          </Typography>
          <form onSubmit={submitForm}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ padding: "10px" }} key={`table`}>
                    <FormControl component="fieldset">
                      <RadioGroup
                          aria-label="answer"
                          name="answer"
                          value={value}
                          onChange={pickAnswer}
                      >
                        {answers.map((answer, key) => (
                            <FormControlLabel
                                value={key}
                                key={key}
                                control={<Radio checked={parseInt(value) === key} />}
                                checked={value == key}
                                label={answer}
                                style={{ marginBottom: "10px", color: "black" }}
                            />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Button
                        disabled={!value}
                        onClick={submitForm}
                        color="primary"
                    >
                      Küldés
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </form>
        </DialogContent>
      </Dialog>
  );
}
