/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';
import styles from '../../assets/jss/material-dashboard-react/views/iconsStyle.js';
import { useDispatch, useSelector } from 'react-redux';
import BetweenQuestionDialog from '../../components/Dialog/BetweenQuestionDialog';
import API, { ENDPOINTS } from '../../api';
import { MENU } from '../../stores/actions/quizActions';
import Countdown from 'react-countdown';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(styles);

const classStyle = {
	displayFlexJustifyEnd: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: '20px',
	},
	displayFlexJustifyCenter: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '20px',
	},
	primaryButton: {
		backgroundColor: '#9c27b0',
		color: '#ffffff',
		boxShadow:
			'0 2px 2px 0 rgb(156 39 176 / 14%), 0 3px 1px -2px rgb(156 39 176 / 20%), 0 1px 5px 0 rgb(156 39 176 / 12%)',
		fontWeight: 400,
		padding: '8px 25px',
		fontSize: '12px',
	},
	secondaryButton: {
		color: '#9c27b0',
		backgroundColor: '#ffffff',
		boxShadow:
			'0 2px 2px 0 rgb(156 39 176 / 14%), 0 3px 1px -2px rgb(156 39 176 / 20%), 0 1px 5px 0 rgb(156 39 176 / 12%)',
		fontWeight: 400,
		padding: '8px 25px',
		fontSize: '12px',
	},
	rightMargin: {
		marginRight: '15px',
	},
};

export default function Curriculum() {
	const mock = [
		{
			id: 0,
			link:
				'https://flipbook.zsirafkreativ.hu/2zsirfKft/mubse-teszt-fejezetek/mubse-vegintezkedesek-keszitese-soran-felmerulo-ugyvedi-muhibak-ifejezet/#/',
			name:
				'Végintézkedések készítése során felmerülő ügyvédi műhibák - I. fejezet',
		},
		{
			id: 0,
			link:
				'https://flipbook.zsirafkreativ.hu/2zsirfKft/mubse-teszt-fejezetek/mubse-vegintezkedesek-keszitese-soran-felmerulo-ugyvedi-muhibak-iifejezet/#/',
			name:
				'Végintézkedések készítése során felmerülő ügyvédi műhibák - II. fejezet',
		},
		{
			id: 0,
			link:
				'https://flipbook.zsirafkreativ.hu/2zsirfKft/mubse-teszt-fejezetek/mubse-vegintezkedesek-keszitese-soran-felmerulo-ugyvedi-muhibak-iiifejezet/#/',
			name:
				'Végintézkedések készítése során felmerülő ügyvédi műhibák - III. fejezet',
		},
		{
			id: 0,
			link:
				'https://flipbook.zsirafkreativ.hu/2zsirfKft/mubse-teszt-fejezetek/mubse-vegintezkedesek-keszitese-soran-felmerulo-ugyvedi-muhibak-ivfejezet/#/',
			name:
				'Végintézkedések készítése során felmerülő ügyvédi műhibák - IV. fejezet',
		},
		{
			id: 0,
			link:
				'https://flipbook.zsirafkreativ.hu/2zsirfKft/mubse-teszt-fejezetek/mubse-vegintezkedesek-keszitese-soran-felmerulo-ugyvedi-muhibak-vfejezet/#/',
			name:
				'Végintézkedések készítése során felmerülő ügyvédi műhibák - V. fejezet',
		},
		{
			id: 0,
			link:
				'https://flipbook.zsirafkreativ.hu/2zsirfKft/mubse-teszt-fejezetek/mubse-vegintezkedesek-keszitese-soran-felmerulo-ugyvedi-muhibak-vifejezet/#/',
			name:
				'Végintézkedések készítése során felmerülő ügyvédi műhibák - VI. fejezet',
		},
	];

	const selectedSubject = useSelector((state) => {
		const { subjects, selectedId } = state.subjectStore;
		return subjects.find((data) => data.id === selectedId);
	});

	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();

	let iFrameRef = React.useRef(null);
	let videoPlayer = React.useRef(null);
	let countDown = React.useRef(null);

	function handleCounterStart() {
		countDown.current?.start();
	}

	function handleCounterPause() {
		countDown.current?.pause();
	}

	const [showComplete, setShowComplete] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [stopped, setStopped] = useState(false);
	const [isBlur, setIsBlur] = useState(false);
	const [isFocused, setIsFocused] = useState(true);
	const [isMobile, setIsMobile] = useState();
	const [showVideoSuccessModal, setShowVideoSuccessModal] = useState(false);
	const [isVideoWatched, setIsVideoWatched] = useState(false);
	const [stepCounter, setStepCounter] = useState(0);
	const [counterDuration, setCounterDuration] = useState(20);

	const subjects = useSelector((state) => {
		return state.subjectStore.subjects;
	});

	const temp = useSelector((state) => {
		return state.subjectStore;
	});

	toast.configure({
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

	// const selectedSubject = () => mock[stepCounter];

	useEffect(() => {
		const vid = document.getElementById('aciveVideo');
		const handleFocus = () => {
			handleCounterStart();
			vid?.play();
		};

		const handleClick = () => {
			setIsFocused((oldFocusState) => {
				const newState = !oldFocusState;
				if (newState) {
					handleCounterStart();
					vid?.play();
				} else {
					handleCounterPause();
					vid?.pause();
				}
				return newState;
			});
		};

		const updateSize = () => {
			window.innerWidth <= 500 ? setIsMobile(true) : setIsMobile(false);
		};

		window.addEventListener('focus', handleFocus);
		document.addEventListener('visibilitychange', handleClick);
		window.addEventListener('resize', updateSize);

		return () => {
			window.removeEventListener('focus', handleFocus);
			document.removeEventListener('visibilitychange', handleClick);
			window.removeEventListener('resize', updateSize);

			//iFrameRef.removeEventListener('focus', handleFocus);
		};
	}, []);

	useEffect(() => {
		dispatch({ type: MENU.SHOW });
		return () => dispatch({ type: MENU.HIDE });
	}, []);


	useEffect(() => {
		if (
			stopped &&
			selectedSubject.pop_questions.length > 0 &&
			!selectedSubject.pop_questions[stepCounter]?.isVideo
		) {
			setCurrentQuestion(selectedSubject.pop_questions[stepCounter]);
		}
	}, [stopped]);

	useEffect(() => {
		window.innerWidth <= 500 ? setIsMobile(true) : setIsMobile(false);
	}, []);

	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a complete state
			return '00:00:00';
		} else {
			// Render a countdown
			return (
				<span>
					{hours < 1 ? '' : hours + ':'}
					{minutes < 10 ? '0' + minutes : minutes}:
					{seconds < 10 ? '0' + seconds : seconds}
				</span>
			);
		}
	};

	function setVideoDuration() {
		const video = videoPlayer.current;
		const duration = Math.floor(video.duration);
		if (!isNaN(duration)) {
			setCounterDuration(duration);
		} else {
			setCounterDuration(
				selectedSubject.pop_questions[stepCounter]?.minutes * 60
			);
		}
		handleCounterPause();
	}

	function showNextQuestion() {
		setStopped(true);
		if (!selectedSubject.pop_questions[stepCounter]?.isVideo) {
			setIsBlur(true);
			setCurrentQuestion(selectedSubject.pop_questions[stepCounter]);
		} else {
			handleVideoWatched();
		}
	}

	function handleVideoWatched() {
		setIsVideoWatched(true);
		API.fetchApiEndpoint(ENDPOINTS.QUIZ.EVALUATE_VIDEO, {
			form: {
				moduleId: selectedSubject.pop_questions[stepCounter]?.id,
			},
		}).then((response) => {
			if (response.success) {
				if (response.data.success) {
					setIsBlur(true);
					setShowVideoSuccessModal(true);
				} else {
					if (response.message) {
						toast.error(response.message);
					}
				}
			}
		});
	}

	function handleAnswer(value) {
		API.fetchApiEndpoint(ENDPOINTS.QUIZ.EVALUATE_POP, {
			form: {
				answerId: value,
				moduleId: currentQuestion.id,
			},
		}).then((response) => {
			if (response.success && response.data.success) {
				toast.success('Helyes válasz.');
				setStepCounter(stepCounter + 1);
				startDate.current = Date.now();

				setCurrentQuestion(null);
				setStopped(false);
				setIsBlur(false);
				if (stepCounter === selectedSubject.pop_questions.length - 1) {
					dispatch({ type: MENU.HIDE });
					setShowComplete(true);
				}
			} else if (response.message === 'Érvénytelen tananyag azonosító!') {
				toast.error('Érvénytelen tananyag azonosító!');
			} else {
				toast.error('Nem sikerült.');
				setStepCounter(stepCounter);
				startDate.current = Date.now();
				setCurrentQuestion(null);
				setStopped(false);
				setIsBlur(false);
			}
		});
	}

	function redirect() {
		return history.push('/admin/quiz');
	}

	function navigeteForward() {
		window.location.replace('https://mubse.hu/Mubse/');
		// return history.push("/admin/chooser");
	}

	const startDate = React.useRef(Date.now());

	return (
		<React.Fragment>
			{!stopped && (
				<div
					style={{
						zIndex: 999,
						position: 'relative',
						textAlign: 'center',
						top: '2px',
						right: '2px',
						margin: '10px 20px 10px 10px',
					}}
				>
					<span
						style={{
							fontSize: '30px',
							fontWeight: 'bold',
							color: '#583030',
							textShadow: '0px 0px 3px #ffffff',
						}}
					>
						{!!selectedSubject.pop_questions[stepCounter]?.minutes && <Countdown
							renderer={renderer}
							ref={countDown}
							date={
								startDate.current +
								+(selectedSubject.pop_questions[stepCounter]
									?.isVideo
									? counterDuration
									: selectedSubject.pop_questions[stepCounter]?.minutes * 60) * 1000
							}
							onComplete={showNextQuestion}
							zeroPadTime={2}
						/>}
						{!selectedSubject.pop_questions[stepCounter]?.minutes &&
							<Button
								style={classStyle.primaryButton}
								onClick={redirect}>
								{selectedSubject.pop_questions[stepCounter]?.isVideo
									? 'Megnéztem a videót'
									: 'Elolvastam az anyagot'}, kérem a kérdéseket
							</Button>}
					</span>
				</div>
			)}

			{currentQuestion &&
			!selectedSubject.pop_questions[stepCounter]?.isVideo &&
			currentQuestion.question !== null ? (
				<BetweenQuestionDialog
					handleAnswer={(value) => handleAnswer(value)}
					{...currentQuestion}
				/>
			) : null}
			{showComplete &&
				!selectedSubject.pop_questions[stepCounter]?.isVideo && (
					<SuccessDialog
						title={`Gratulálunk! Sikeresen teljesítette a Tananyagot. Eredményét rögzítettük, a MÜBSE két munkanapon belül értesíti a 
            ${selectedSubject.modules_credits} kreditpont megszerzéséről. Az Egyesület a Magyar Ügyvédi Kamara felületére pedig 15 napon belül tölti fel az Ön kreditpontjait. Kattintson tovább a Tesztre 3 újabb kreditpontért.`}
						callback={redirect}
					/>
				)}
			{showVideoSuccessModal &&
			selectedSubject.pop_questions[stepCounter]?.isVideo ? (
				<VideoSuccessDialog
					show={showVideoSuccessModal}
					closeCallback={() => {
						setShowVideoSuccessModal(false);
						setIsBlur(false);
					}}
					callback={navigeteForward}
					title={`Gratulálunk, sikeresen megtekintette az előadást. A MÜBSE hamarosan értesíti a ${selectedSubject.modules_credits} kreditpont megszerzéséről.`}
				/>
			) : null}
			<GridContainer
				style={{
					transition: '.5s',
					opacity: isBlur ? 0.5 : 1,
					filter: isBlur ? 'blur(10px)' : 'blur(0px)',
				}}
			>
				<GridItem xs={12} sm={12} md={12}>
					<Card plain>
						<CardBody>
							{selectedSubject &&
								!selectedSubject.pop_questions[stepCounter]
									?.isVideo &&
								isMobile && (
									<iframe
										style={{
											marginTop: '-20px',
											height: '400px',
										}}
										ref={iFrameRef}
										className={classes.iframe}
										src={
											selectedSubject.pop_questions[
												stepCounter
											]?.link
										}
										title="Flipbook anyagok"
									>
										<p>
											Your browser does not support
											iframes.
										</p>
									</iframe>
								)}
							{selectedSubject &&
								!selectedSubject.pop_questions[stepCounter]
									?.isVideo &&
								!isMobile && (
									<iframe
										style={{
											marginTop: '-20px',
										}}
										ref={iFrameRef}
										className={classes.iframe}
										src={
											selectedSubject.pop_questions[
												stepCounter
											]?.link
										}
										title="Flipbook anyagok"
									>
										<p>
											Your browser does not support
											iframes.
										</p>
									</iframe>
								)}
							{selectedSubject &&
								selectedSubject.pop_questions[stepCounter]
									?.isVideo &&
								selectedSubject.pop_questions[stepCounter]
									?.link && (
									<>
										{isVideoWatched && (
											<div
												style={
													classStyle.displayFlexJustifyCenter
												}
											>
												<Button
													disabled={!isVideoWatched}
													onClick={navigeteForward}
													style={
														classStyle.primaryButton
													}
													color="primary"
												>
													Tovább a MÜBSE oldalára
												</Button>
											</div>
										)}
										<video
											id="aciveVideo"
											width="100%"
											controls
											controlsList="nodownload noplaybackrate"
											ref={videoPlayer}
											onDurationChange={setVideoDuration}
											onPause={handleCounterPause}
											onPlay={handleCounterStart}
											onEnded={handleCounterStart}
										>
											<source
												src={
													selectedSubject
														.pop_questions[
														stepCounter
													]?.link
												}
												type="video/mp4"
											></source>
											A böngészője nem támogatja a
											videólejátszást.
										</video>
									</>
								)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</React.Fragment>
	);
}

function SuccessDialog({ title, callback }) {
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			open={true}
			onClose={callback}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title"> {title} </DialogTitle>
			<DialogContent>
				<Button onClick={callback} color="primary">
					{' '}
					Tovább a kvíz kitöltésére
				</Button>
			</DialogContent>
		</Dialog>
	);
}

function VideoSuccessDialog({ title, show, callback, closeCallback }) {
	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			open={show}
			onClose={closeCallback}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title"> {title} </DialogTitle>
			<DialogContent>
				<p>
					Szeretné újra megtekinteni a videót vagy visszatér a MÜBSE
					oldalára?
				</p>
				<div style={classStyle.displayFlexJustifyEnd}>
					<Button
						onClick={closeCallback}
						color="secondary"
						style={{
							...classStyle.secondaryButton,
							...classStyle.rightMargin,
						}}
					>
						Videó folytatása
					</Button>
					<Button
						onClick={callback}
						color="primary"
						style={classStyle.primaryButton}
					>
						Tovább a MÜBSE oldalára
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
