import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import createStore from "./stores/rootStore";

import Admin from "layouts/Admin.js";
import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();
const store = createStore();

console.log(process.env.REACT_APP_BUILD_ENV);

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Redirect from="/" to="/admin/tananyag" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
