/* eslint-disable */

import { appSettings } from "./variables/settings";

export const ENDPOINTS = {
  USERS: {
    LOGIN: "/security/login",
    TOKEN_LOGIN: "/security/token/login",
    VALIDATE_TOKEN: "/security/validate",
  },
  QUIZ: {
    GET_ONGOING: "/get_ongoing",
    GET_SUBJECTS: "/courses/all",
    POST_END_TIME: "/post_end_time",
    SAVE_FILLING: "/save_filling",
    EVALUATE: "/evaluate/question",
    EVALUATE_VIDEO: "/evaluate/video",
    EVALUATE_POP: "/evaluate/module",
  },
};

const endpoints = new Map([
  [
    "/security",
    new Map([
      [
        "login",
        {
          uri: ENDPOINTS.USERS.LOGIN,
          method: "POST",
          required_params: {
            form: ["email", "password"],
          },
        },
      ],
      [
        "validate_token",
        {
          uri: ENDPOINTS.USERS.VALIDATE_TOKEN,
          method: "POST",
          required_params: {
            form: ["token"],
          },
        },
      ],
    ]),
  ],
  [
    "token",
    new Map([
      [
        "token_login",
        {
          uri: ENDPOINTS.USERS.TOKEN_LOGIN,
          method: "POST",
          required_params: {
            form: ["token", "courseId"],
          },
        },
      ],
    ]),
  ],
  [
    "quiz",
    new Map([
      [
        "evaluate",
        {
          uri: ENDPOINTS.QUIZ.EVALUATE,
          method: "POST",
          required_params: {
            form: ["answers", "selectedSubjectId", "themeId"],
          },
        },
      ],
      [
        "evaluate_pop",
        {
          uri: ENDPOINTS.QUIZ.EVALUATE_POP,
          method: "POST",
          required_params: { form: ["answerId", "moduleId"] },
        },
      ],
      [
        "evaluate_video",
        {
          uri: ENDPOINTS.QUIZ.EVALUATE_VIDEO,
          method: "POST",
          required_params: { form: ["moduleId"] },
        },
      ],
      [
        "get_all_subjects",
        {
          uri: ENDPOINTS.QUIZ.GET_SUBJECTS,
          method: "GET",
          required_params: {},
        },
      ],
      [
        "get_ongoing",
        {
          uri: ENDPOINTS.QUIZ.GET_ONGOING,
          method: "GET",
          required_params: {},
        },
      ],
      [
        "post_end_time",
        {
          uri: ENDPOINTS.QUIZ.POST_END_TIME,
          method: "POST",
          required_params: {
            form: ["endTime", "selectedSubjectId", "email"],
          },
        },
      ],
      [
        "save_filling",
        {
          uri: ENDPOINTS.QUIZ.SAVE_FILLING,
          method: "POST",
          required_params: {
            form: ["filling_id", "filling"],
          },
        },
      ],
    ]),
  ],
]);

export default class API {
  static endpoint_url = appSettings.api_url;

  static fetchApiEndpoint(uri, params = { form: {}, query: {} }) {
    let request_obj = null;
    endpoints.forEach((group) => {
      group.forEach((endpoint_obj) => {
        if (endpoint_obj.uri === uri) {
          request_obj = endpoint_obj;
        }
      });
    });
    if (request_obj === null) {
      throw "Missing endpoint uri!";
    }
    let fetch_url = `${this.endpoint_url}${request_obj.uri}`;

    /** query building */
    if (Object.prototype.hasOwnProperty.call(params, "query")) {
      if (
        Object.prototype.hasOwnProperty.call(
          request_obj.required_params,
          "query"
        )
      ) {
        request_obj.required_params.query.forEach((query_param) => {
          if (Object.prototype.hasOwnProperty.call(params.query, query_param)) {
            fetch_url = `${fetch_url}/${params.query[query_param]}`;
          } else {
            throw `Missing query param: ${query_param}`;
          }
        });
      }
    }

    /** formData building */
    let is_form = false;
    const formData = new FormData();
    if (Object.prototype.hasOwnProperty.call(params, "form")) {
      if (
        Object.prototype.hasOwnProperty.call(
          request_obj.required_params,
          "form"
        )
      ) {
        is_form = true;
        // formData.append('csrf', Cookies.get('csrfToken'));
        request_obj.required_params.form.forEach((form_param) => {
          if (Object.prototype.hasOwnProperty.call(params.form, form_param)) {
            if (Array.isArray(params.form[form_param])) {
              params.form[form_param].forEach((param) => {
                formData.append(`${form_param}[]`, param);
              });
            } else {
              formData.append(form_param, params.form[form_param]);
            }
          } else {
            throw `Missing form param: ${form_param}`;
          }
        });
      }
    }

    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    /*        fetch('https://mubse.webdream.hu/api/courses/all', {
            header: {
                "content-type": 'application/ld+json',
                'http-accept': 'application/ld+json',
                'http-authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTUzODY3NTEsImV4cCI6MTYxNTQwNDc1MSwidXNlcm5hbWUiOiJ3ZWJkcmVhbV9zZWNyZXQiLCJjb3Vyc2VJZCI6NH0.arAU3fNrhO_zl70vp6v4v2tKPGq-z7y6FivJ3Qkckch4gOW2J-CeFMrw8mK8jwF03EsiZNLvp1-3xsRVi4tF09fArQvW4Hg3UArwzimD922yuehDmFpP8Ep4-Bq1PKweintR7eghX5fhcgmDcU_X6l-5YkuR4VTa3mwrufFihs17w3eZRoXZjIjzudGttCuojvN-SlbbUVeDr1wtb4bE6v-F5yr_PXsXhK7w-ltquyikJPW0RA-gEt7kOUI6ar34bFPWsRPtYK2zky0Ow8m6EuCsnddtmP_7iwfnaaKZVyRUa1lmG4bE4_F7ZvYh8VvRJqOFeQ0QK5BLqQLjQAXU2HEA6sbSNq5SW-TCeUX097CPEXSLry587MyNbKBLe23gdRSsvWzV7mt61Trljrr549ZBTXOGvz5NudzrLNzOzU-dSqL00KbU8FrU1WaxfzeKwMbFNG9wTgUPqB55mEougG9cmjsBOV5Bes4-xz8JFiV5qVl-rj8_pKNFelrto32PysZNswCzzokUnaVQnDu8tKmNQHL4pBppLM6vx5E6KoP1ttmMU7nhySjHvp5DFV7BOTjkE16EzKUoNDaC9R-dDgwSebBDZvy1MCEAv_4wQDZBisR8lYjcWI9e0nreYSaVyJOpb8zC8KJR_i5ItlLYhTpTLEG87CsqEua6_wuCW80'
            }
        }).then(console.log)*/
    /** request object building */

    const optionalData =
      request_obj.uri === ENDPOINTS.USERS.TOKEN_LOGIN
        ? {}
        : { headers: { Authorization: `Bearer ${token}` } };
    let req = {
      method: request_obj.method,
      credentials: "include",
      mode: "cors",
      ...optionalData,
    };

    if (is_form) {
      req.body = formData;
    }

    /** fetching endpoint */
    return fetch(fetch_url, { ...req }).then((response) => {
      return response.json();
    });
  }
}
