/* eslint-disable */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import API, { ENDPOINTS } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { SUBJECTS } from "../../stores/actions/quizActions";
import { ScaleLoader } from "react-spinners";
import { Grid } from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import "./chooser.scss";
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

export default function Chooser() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [canRedirect, setCanRedirect] = useState(false);
  const availableSubjects = useSelector(state => state.subjectStore.subjects);
  const selectedSubjectId = useSelector(state => state.subjectStore.selectedId);
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    API.fetchApiEndpoint(ENDPOINTS.QUIZ.GET_SUBJECTS, {})
      .then(r => {

        if (r.success) {
          let subjects = [];
          r.data.subjects.map((subject, id) => {
            subjects.push({
              id: id,
              name: subject.name,
              link: subject.link,
              questions: subject.questions,
              pop_questions: subject.pop_questions
            });
          });
          dispatch({
            type: SUBJECTS.SET_SUBJECTS,
            payload: { subjects: subjects, completed: r.completed }
          });
          setLoaded(true);
        }
        setOpen(true);
      })
      .catch(err => console.error(err));
  }, []);

  const handleClose = value => {
    dispatch({
      type: SUBJECTS.SELECT_SUBJECT,
      payload: Number(value) || 0
    });
    history.push("/admin/tananyag");
  };

  return (
    <div>
      {!loaded ? (
        <ScaleLoader
          height={10}
          width={2}
          radius={30}
          margin={2}
          loading={!loaded}
        />
      ) : null}

      {availableSubjects.map((subject, key) => {

        return (
          <Grid key={key}>
            <GridContainer>
              <GridItem xs={10} sm={10} md={6}>
                <Card>
                  <h4 className={"card-title"}>{subject.name}</h4>
                  <CardBody>
                    <p>
                      {subject.pop_questions.length} rendelkezésreálló anyag
                    </p>
                    <Button
                      onClick={() => handleClose(subject.id)}
                      type={"button"}
                      color="primary"
                    >
                      Elkezdem
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Grid>
        );
      })}
      {/*<Dialog disableBackdropClick disableEscapeKeyDown open={open}>
                <DialogTitle>Figyelem!</DialogTitle>
                <DialogContent>
                    <p>
                        A témakör kiválasztása után <strong>{appSettings.readTime}</strong> perc áll a rendelkezésére a
                        tananyag olvasására és <strong>{appSettings.quizTime}</strong> perc a kérdéssor
                        kitöltésére.<br/>
                        Miután megkezdte a kvíz kitöltését, már nem tudja újra elolvasni a tananyagot.
                    </p>
                    <form className={classes.container}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-dialog-select-label">Témakör</InputLabel>
                            <Select
                                id="demo-dialog-select"
                                value={selectedSubjectId === undefined ? 0 : selectedSubjectId}
                                onChange={event => {
                                    dispatch({
                                        type: SUBJECTS.SELECT_SUBJECT,
                                        payload: Number(event.target.value) || 0
                                    });
                                }}
                                input={<Input/>}
                            >
                                {availableSubjects.map(subject => {
                                    return (
                                        <MenuItem
                                            key={`menu_item_${subject.id}`}
                                            value={subject.id}
                                        >
                                            {subject.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Választ
                    </Button>
                </DialogActions>
            </Dialog>*/}
    </div>
  );
}
