/* eslint-disable */

import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import API, {ENDPOINTS} from "../api";
import {useDispatch, useSelector} from "react-redux";
import {USER} from "../stores/actions/quizActions";
import {CircleLoader, ScaleLoader} from "react-spinners";
import {useHistory} from "react-router";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function SignIn({loading}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [inLoading, setInLoading] = useState(loading);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const form = useRef();
    const {push} = useHistory();

    useEffect(() => {
        setInLoading(loading);
    }, [loading]);

    function onSubmit(event) {
        if (event.target.checkValidity()) {
            event.preventDefault();
            setInLoading(true);
            API.fetchApiEndpoint(ENDPOINTS.USERS.LOGIN, {
                form: {email: email, password: password}
            })
                .then(r => {
                    if (r.success) {

                        sessionStorage.setItem("token", r.data.token);
                        dispatch({type: USER.LOGIN, payload: {user: r.data}});
                        push('/admin/chooser')
                    } else {
                        setError(true);
                        setErrorMessage(r.message);
                        setPassword("");
                    }
                    setInLoading(false);
                })
                .catch(console.log);
            // .finally(() => {
            //     setError(true);
            //     // setErrorMessage('Hiba történt!');
            //     setPassword('');
            // });
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            {/*<CssBaseline/>
            <div className={classes.paper}>
                {inLoading ? (
                    <ScaleLoader height={10} width={2} radius={30} margin={2}/>
                ) : (
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                )}
                <form
                    ref={form}
                    className={classes.form}
                    onSubmit={onSubmit}
                >
                    <span style={{color: "red"}}>{errorMessage}</span>
                    <TextField
                        disabled={loading}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={error}
                        id="email"
                        label="E-mail cím"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        disabled={loading}
                        variant="outlined"
                        error={error}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Jelszó"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    {inLoading ? null : (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Bejelentkezés
                        </Button>
                    )}
                </form>
            </div>*/}
        </Container>
    );
}
