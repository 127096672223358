//const env = process.env;

const ulrsByEnvironments = {
  webpackServer: "https://mubse.webdream.hu",
  developmentServer: "https://mubse.webdream.dev",
  producionServer: "https://mubse.webdream.hu"
};

const url = ulrsByEnvironments[process.env.REACT_APP_BUILD_ENV];

const appSettings = {
  readTime: 5, // 60 * 20 (mp)
  quizTime: 1,
  api_url: `${url}/api`
};

export { appSettings };
