/* eslint-disable */

import React, {useEffect} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import {makeStyles} from "@material-ui/core/styles";
import Sidebar from "../components/Sidebar/Sidebar.js";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import logo from "../assets/img/MUBSE_logo.svg";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import API, {ENDPOINTS} from "../api";
import {SUBJECTS, USER} from "../stores/actions/quizActions";

let ps;

const useStyles = makeStyles(styles);

export default function Admin({...rest}) {
	// useBeforeunload(() => "Biztosan el kívánja hagyni az oldalt?");
	const classes = useStyles();
	const mainPanel = React.createRef();
	const [color] = React.useState("blue");
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const {push} = useHistory();
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};
	const user = useSelector(state => state.userStore);
	const availableRoutes = useSelector(
		state => state.navigationStore.availableRoutes
	);
	const selectedSubject = useSelector(state => {
		if (state.subjectStore.subjects.length > 0) {
			return state.subjectStore.subjects[state.subjectStore.selectedId];
		}
		return {name: "Bejelentkezés"};
	});

	const dispatch = useDispatch();
	useEffect(() => {
		const params = new URLSearchParams(document.location.search.substring(1))
		const token = params.get('token');
		const courseId = params.get('themeId');
		const state = params.get('fazis');

		if (state && courseId && token) {
			API.fetchApiEndpoint(ENDPOINTS.USERS.TOKEN_LOGIN, {
				form: {token: token, courseId: courseId}
			})
				.then(r => {
					if (r.success) {
						window.localStorage.setItem('token', r.data.token)
						API.fetchApiEndpoint(ENDPOINTS.QUIZ.GET_SUBJECTS, {token: r.data.token})
							.then(r => {

								if (r.success) {
									let subjects = [];
									r.data.subjects.map((subject, id) => {
										subjects.push({
											id: id,
											_id: subject.id,
											name: subject.name,
											link: subject.link,
											questions: subject.questions,
											modules_credits: subject.modules_credits,
											quiz_credits: subject.quiz_credits,
											pop_questions: subject.pop_questions
										});
									});

									dispatch({
										type: SUBJECTS.SET_SUBJECTS,
										payload: {subjects: subjects, completed: r.completed}
									});

									dispatch({type: USER.LOGIN, payload: {user: null}});

									push(state === '0' ? "/admin/tananyag" : "/admin/quiz");
								}
							})
							.catch(err => console.error(err));
					} else {

					}
				})
				.catch(console.log);
		}
	});


	useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false
			});
			document.body.style.overflow = "hidden";
		}
		window.addEventListener("resize", resizeFunction);
		// Specify how to clean up after this effect:
		return function cleanup() {
			if (navigator.platform.indexOf("Win") > -1) {
				ps.destroy();
			}
			window.removeEventListener("resize", resizeFunction);
		};
	}, [mainPanel]);

	return (
		<div className={classes.wrapper}>
			<div style={{display: 'none'}}>
				<Sidebar
					routes={availableRoutes}
					logoText={"MÜBSE E-learning"}
					logo={logo}
					image={"undefined"}
					handleDrawerToggle={handleDrawerToggle}
					open={mobileOpen}
					color={color}
					{...rest}
				/>
			</div>
			<div className={classes.mainPanel} ref={mainPanel}>
				<div style={{display: "flex", justifyContent: "center"}}>
					<Typography variant="h6" className={classes.title}>
						{selectedSubject.hasOwnProperty("name")
							? selectedSubject.name
							: null}
					</Typography>
				</div>
				<div className={classes.content}>
					<div className={classes.container}>
						<Switch>
							{availableRoutes.map((prop, key) => {
								if (prop.layout === "/admin") {
									return (
										<Route
											path={prop.layout + prop.path}
											component={prop.component}
											key={`r_${key}`}
										/>
									);
								}
							})}
						</Switch>
					</div>
				</div>
			</div>
		</div>
	);
}
