export const READTIMER = {
  START: Symbol("reading started"),
  STOP: Symbol("reading ended"),
  TICK: Symbol("Tick"),
  SHUTDOWN: Symbol("inbetween question"),
  SET_END: Symbol("Set timer end date")
};

export const TIMER = {
  START: Symbol("quiz started"),
  STOP: Symbol("quiz ended"),
  TICK: Symbol("Tick"),
  SET_END: Symbol("Set timer end date")
};

export const QUIZ = {
  SET_ANSWERS: Symbol("set choosen answers"),
  SET_SCORE: Symbol("set score"),
  SET_FILLING_ID: Symbol("set score"),
  RESET_SCORE: Symbol("set score"),
  ABORT: Symbol("set score")
};

export const USER = {
  LOGIN: Symbol("User login"),
  LOGOUT: Symbol("User logout"),
  SET_EMAIL: Symbol("Set user email")
};

export const NAVIGATION = {
  SET_AVAILABLE: Symbol("Set available routes")
};

export const SUBJECTS = {
  SET_SUBJECTS: Symbol("Set available subjects"),
  SELECT_SUBJECT: Symbol("Select subject")
};
export const MENU = {
  SHOW: Symbol("Set menu visible"),
  HIDE: Symbol("Set menu invsible")
};
