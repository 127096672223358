/*eslint-disable*/
import React, {Component} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Timer from "../Timer/Timer";
import {appSettings} from "../../variables/settings";

export default function FixedPlugin(props) {
  const handleClick = () => {
    props.handleFixedClick();
  };
  return (
    <div
      className={classnames("fixed-plugin", {
        "rtl-fixed-plugin": props.rtlActive
      })}
    >
      <div id="fixedPluginClasses" className={props.fixedClasses}>
        <div onClick={handleClick}>
          <Timer totalTime={appSettings.quizTime} emergencyTreshold={1}/>
        </div>
      </div>
    </div>
  );
}

FixedPlugin.propTypes = {
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  rtlActive: PropTypes.bool,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red", "grey"]),
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func
};
