/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import { QUIZ, TIMER } from "../../stores/actions/quizActions";
import { Navigation } from "@material-ui/icons";
import API, { ENDPOINTS } from "../../api";
import Button from "../CustomButtons/Button.js";
import { useHistory } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
  ...styles,
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const CustomTable = (props) => {
  const dispatch = useDispatch();
  const isStarted = useSelector((state) => state.timerStore.isStarted);

  const selectedSubject = useSelector((state) => {
    const { subjects, selectedId } = state.subjectStore;

    return subjects.find((data) => data.id === selectedId);
  });

  const completed = useSelector((state) => state.subjectStore.completed);

  const choosenAnswers = useSelector((state) => state.quizStore.answers);
  const fillingId = useSelector((state) => state.quizStore.fillingId);
  const user = useSelector((state) => state.userStore);
  const score = useSelector((state) => state.quizStore.score);
  const classes = useStyles;
  const [answersObject, setAnswersObject] = useState({});
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [resetDialogVisibiliy, setResetDialogVisibiliy] = useState(false);
  const [tableData, setTableData] = useState(props.tableData);
  const { push } = useHistory();
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const shuffleArray = (arr) =>
    arr
      .map((a) => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map((a) => a[1]);

  useEffect(() => {
    setTableData(
      shuffleArray(selectedSubject.questions)
        .slice(0, 15)
        .map(({ id, name, answers }) => {
          return [id, name, answers];
        })
    );
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    setCurrentQuestion(params.get("themeId"));
  });

  const toggleAnswerState = (question, questionId, answerKey) => {
    const answersObjectClone = { ...answersObject };
    answersObjectClone[questionId] = answerKey;
    setAnswersObject(answersObjectClone);
    dispatch({ type: QUIZ.SET_ANSWERS, payload: answersObjectClone });
  };

  useEffect(() => setAnswersObject({}), [fillingId]);

  const evaluateQuiz = () => {
    console.log({
      user: user,
      answers: choosenAnswers,
      selectedSubjectId: selectedSubject._id,
      email: user.email,
      themeId: selectedSubject._id,
    });
    dispatch({ type: TIMER.STOP });
    API.fetchApiEndpoint(ENDPOINTS.QUIZ.EVALUATE, {
      form: {
        answers: JSON.stringify(choosenAnswers),
        selectedSubjectId: selectedSubject._id,
        themeId: selectedSubject._id,
      },
    })
      .then((r) => {
        if (r.success) {
          if (r.score_added) {
            setIsDialogVisible(true);
            // dispatch({ type: QUIZ.SET_SCORE, payload: r.data.score });
          } else {
            setResetDialogVisibiliy(true);
          }
        } else if (r.message === 'Érvénytelen tananyag azonosító!') {
			window.alert("Érvénytelen tananyag azonosító! Egy másik tananyag is meg lett nyitva.");
			setResetDialogVisibiliy(true);
		} else {

		}
      })
      .catch(console.log);
  };

  let outsiderCounter = 0;
  let shuffledStuff = [1, 2, 3].sort(() => Math.random() - 0.5);
  const giveThree = () => {
    outsiderCounter++;
    if (outsiderCounter % 3 === 0) {
      shuffledStuff = [1, 2, 3].sort(() => Math.random() - 0.5);
      outsiderCounter = 0;
    }
    return shuffledStuff.pop();
  };
  const history = useHistory();
  const resetLearning = () => {
    dispatch({ type: QUIZ.RESET_SCORE });
    setAnswersObject({});
    setResetDialogVisibiliy(false);
  };

  const renderQuiz = () => {
    return (
      <div
        className={classes.tableResponsive}
        style={{ filter: score !== null ? `blur(10px)` : "none" }}
      >
        <Table className={classes.table}>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={isDialogVisible}
            onClose={() => window.location.replace("https://mubse.hu/Mubse/")}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {
                !!selectedSubject.quiz_credits
                    ? `Gratulálunk! Sikeresen teljesítette a Tesztet. Eredményét rögzítettük, a MÜBSE két munkanapon belül értesíti a ${selectedSubject.quiz_credits} kreditpont megszerzéséről. Az Egyesület a Magyar Ügyvédi Kamara felületére pedig 15 napon belül tölti fel az Ön kreditpontjait.`
                    : 'Helyesen válaszolta meg a fenntarthatósági anyag kérdéseit. A sikeres pályázat érdekében kérjük kísérje figyelemmel az újabb anyagokat, melyek megjelenéséről hírlevélben értesítjük majd.'

              }
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() =>
                    window.location.replace("https://mubse.hu/Mubse/")
                  }
                  color="primary"
                >
                  Vissza a MÜBSE oldalára
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={resetDialogVisibiliy}
            onClose={() => window.location.replace("https://mubse.hu/Mubse/")}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Sikertelen kitöltés
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Button onClick={() => resetLearning()} color="primary">
                  A tananyag újrakezdése.
                </Button>
              </div>
            </DialogContent>
          </Dialog>
          <TableBody>
            {tableData.map(([id, kerdes, valaszok], key) => (
              <TableRow key={`tbr${key}`} className={classes.tableBodyRow}>
                <TableCell
                  className={classes.tableCell}
                  style={{ padding: "10px", verticalAlign: "top" }}
                  key={`tbc_${key}`}
                >
                  <strong
                    style={{
                      paddingTop: "4px",
                      display: "block",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    {key + 1}
                    {")"}
                  </strong>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ fontSize: "1.2rem", minWidth: "50vw" }}
                  key={`tbc2_${key}`}
                >
                  <div style={{ marginLeft: "-10px" }}>{kerdes}</div>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {valaszok.map((valasz, key_) => {
                      return (
                        <FormControlLabel
                          key={`${key_}`}
                          style={{
                            color: "#363636",
                            alignItems: "top",
                            order: giveThree()[key_],
                          }}
                          control={
                            <Checkbox
                              key={`cb_${key_}`}
                              value={JSON.stringify({
                                question: { id },
                                answer: { key_ },
                              })}
                              checked={answersObject?.[id] === key_}
                              name={`cb_${key}_${key_}`}
                              onChange={() =>
                                toggleAnswerState(kerdes, id, key_)
                              }
                            />
                          }
                          label={valasz}
                        />
                      );
                    })}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            color="primary"
            disabled={
              !Object.values(choosenAnswers).length === tableData.length
            }
            onClick={() => evaluateQuiz()}
          >
            <Navigation />
            Elküldés kiértékelésre
          </Button>
        </div>
      </div>
    );
  };

  function AlreadyCompleated() {
    return (
      <div style={{ height: "100%", display: "block", textAlign: "center" }}>
        <h4>
          A tananyaghoz tartozó quiz már kilett töltve a felhasználó által.
        </h4>
        <Button
          onClick={() => window.location.replace("https://mubse.hu/Mubse/")}
          color="primary"
        >
          Irány a MÜBSE oldala
        </Button>
      </div>
    );
  }

  return !completed ? renderQuiz() : <AlreadyCompleated />;
};

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.string,
  tableHead: PropTypes.array,
  tableData: PropTypes.array,
};

export default CustomTable;
