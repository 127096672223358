/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QUIZ, TIMER } from '../../stores/actions/quizActions';
import moment from 'moment';
import API, { ENDPOINTS } from '../../api';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '../CustomButtons/Button.js';

export default ({ totalTime, emergencyTreshold }) => {
  const dispatch = useDispatch();
  const [classes] = useState('dropdown show');
  const fillingId = useSelector((state) => state.quizStore.fillingId);

  const isStarted = useSelector((state) => {
    return state.timerStore.isStarted;
  });

  const time = useSelector((state) => {
    return state.timerStore.time;
  });

  const endTime = useSelector((state) => state.timerStore.endTime);
  const score = useSelector((state) => state.quizStore.score);

  const subjectStore = useSelector((state) => {
    if (state.subjectStore.length === 0) {
      return { ...state.subjectStore, selectedId: 0 };
    }
    return state.subjectStore;
  });

  const userEmailStore = useSelector((state) => state.userStore.email);

  const starter = () => dispatch({ type: TIMER.START, payload: totalTime });
  const [evaluating, setEvaluating] = useState(false);
  const choosenAnswers = useSelector((state) => state.quizStore.answers);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const selectedSubject = useSelector((state) => {
    const { subjects, selectedId } = state.subjectStore;

    return subjects.find((data) => data.id === selectedId);
  });

  useEffect(() => {
    setInterval(() => {
      dispatch({ type: TIMER.TICK });
    }, 1000);
  }, []);

  useEffect(() => {
    /** lejárt az idő */
    if (isStarted && time >= endTime) {
      setEvaluating(true);
      setIsDialogVisible(true);
      dispatch({ type: TIMER.STOP });
      API.fetchApiEndpoint(ENDPOINTS.QUIZ.EVALUATE, {
        form: {
          answers: JSON.stringify(choosenAnswers),
          selectedSubjectId: selectedSubject._id,
          themeId: selectedSubject._id,
        },
      })
        .then((r) => {
          if (r.success) {
            dispatch({ type: QUIZ.SET_SCORE, payload: r.data.score });
          } else if (r.message === 'Érvénytelen tananyag azonosító!') {
            window.alert(
              'Érvénytelen tananyag azonosító! Egy másik tananyag is meg lett nyitva.'
            );
          }
          setEvaluating(false);
        })
        .catch((err) => {
          console.error(err);
          setEvaluating(false);
        });
      dispatch({ type: TIMER.STOP });
    }
  }, [time]);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    setCurrentQuestion(params.get('themeId'));
  }),
    useEffect(() => {
      if (endTime !== null) {
        /*API.fetchApiEndpoint(ENDPOINTS.QUIZ.POST_END_TIME, {
				  form: {
					endTime: endTime.format(`YYYY-MM-DD HH:mm:ss`),
					selectedSubjectId: subjectStore.selectedId,
					email: userEmailStore
				  }
				})
				  .then(r => {
					if (r.success) {
					  dispatch({ type: QUIZ.SET_FILLING_ID, payload: r.data.id });
					} else {
					  dispatch({ type: QUIZ.ABORT });
					}
				  })
				  .catch(err => console.error(err));*/
      }
    }, [endTime]);

  const renderTime = () => {
    if (time !== null) {
      let timeDiff = endTime.diff(time);
      return (
        <div style={{ margin: '10px 20px 10px 10px' }}>
          <span
            style={{
              fontSize: '30px',
              fontWeight: 'bold',
              color:
                moment.duration(timeDiff).as('seconds') <=
                emergencyTreshold * 60
                  ? '#583030'
                  : 'black',
            }}
          >{`${moment(timeDiff).format(`mm:ss`)}`}</span>
        </div>
      );
    } else {
      return 'indulás';
    }
  };

  const renderStarter = () => {
    return <div style={{ padding: '5px 20px 5px 10px' }}></div>;
  };

  return (
    <>
      <span>{isStarted ? renderTime() : renderStarter()}</span>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={isDialogVisible}
        onClose={() => window.location.replace('https://mubse.hu/Mubse/')}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Túllépte a rendelkezésre álló 30 perces időkeretet
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => window.location.replace('https://mubse.hu/Mubse/')}
              color="primary"
            >
              Vissza a MÜBSE oldalára
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
