/* eslint-disable */

import { createStore, combineReducers } from "redux";
import {
  QUIZ,
  TIMER,
  READTIMER,
  USER,
  NAVIGATION,
  SUBJECTS,
  MENU
} from "./actions/quizActions";
import * as moment from "moment";
import Login from "../views/Login/Login";

const readTimerDefaultState = {
  time: moment(),
  endTime: null,
  isStarted: false,
  hasEnded: false,
  stopped: false
};
const menuStoreDefaultState = { hidden: false };
const timerDefaultState = {
  time: moment(),
  endTime: null,
  isStarted: false,
  hasEnded: false
};
const userDefaultState = { user: {}, loggedIn: false, email: null};
const quizDefaultStore = { answers: {}, score: null, fillingId: null };
const subjectDefaultState = { pop_questions: [], subjects: [], selectedId: 0, completed: false, isVideo: false};
const navigationDefaultState = {
  availableRoutes: [
    {
      path: "/login",
      name: "Bejelentkezés",
      icon: "content_paste",
      component: Login,
      layout: "/admin"
    }
  ]
};

const rootReducer = combineReducers({
  quizStore: (state = quizDefaultStore, action) => {
    switch (action.type) {
      case QUIZ.SET_FILLING_ID:
        return { ...state, fillingId: action.payload };
      case QUIZ.SET_SCORE:
        return { ...state, score: action.payload };
      case QUIZ.SET_ANSWERS:
        return { ...state, answers: action.payload };
      case QUIZ.RESET_SCORE:
        return { ...state, score: quizDefaultStore.score };
      case QUIZ.ABORT:
        return quizDefaultStore;
      default:
        return state;
    }
  },
  menuStore: (state = menuStoreDefaultState, action) => {
    switch (action.type) {
      case MENU.SHOW:
        return { hidden: true };
      case MENU.HIDE:
        return { hidden: false };
      default:
        return state;
    }
  },
  readTimerStore: (state = readTimerDefaultState, action) => {
    switch (action.type) {
      case READTIMER.START:
        return {
          ...state,
          time: moment(),
          endTime: moment().add(action.payload, "seconds"),
          isStarted: true,
          stopped: false
        };
      case READTIMER.STOP:
        return { ...state, isStarted: true, stopped: true };
      case READTIMER.TICK:
        return { ...state, time: moment() };
      default:
        return state;
    }
  },
  timerStore: (state = timerDefaultState, action) => {
    switch (action.type) {
      case TIMER.START:
        return {
          ...state,
          time: moment(),
          endTime: moment().add(action.payload, "minutes"),
          isStarted: true
        };
      case TIMER.STOP:
        return { ...state, isStarted: false, hasEnded: true };
      case TIMER.TICK:
        return { ...state, time: moment() };
      default:
        return state;
    }
  },
  userStore: (state = userDefaultState, action) => {
    switch (action.type) {
      case USER.LOGIN:
        return { ...action.payload, loggedIn: true };
      case USER.LOGOUT:
        return { user: {}, loggedIn: false };
      default:
        return state;
    }
  },
  navigationStore: (state = navigationDefaultState, action) => {
    switch (action.type) {
      case NAVIGATION.SET_AVAILABLE:
        return { ...action.payload };
      default:
        return state;
    }
  },
  subjectStore: (state = subjectDefaultState, action) => {
    switch (action.type) {
      case SUBJECTS.SET_SUBJECTS:
        return { ...state, ...action.payload };
      case SUBJECTS.SELECT_SUBJECT:
        return { ...state, selectedId: action.payload };
      default:
        return state;
    }
  }
});
export default () => createStore(rootReducer);
