/* eslint-disable */

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomTable from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import { appSettings } from "../../variables/settings";
import FixedPlugin from "../../components/FixedPlugin/FixedPlugin";
import ResultDialog from "../../components/Quiz/ResultDialog";
import BetweenQuestionDialog from "../../components/Dialog/BetweenQuestionDialog";
import { MENU, TIMER } from "../../stores/actions/quizActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleBlack: {
    color: "#000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function Quiz() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isStarted = useSelector(state => state.timerStore.isStarted);
  const score = useSelector(state => state.quizStore.score);
  const user = useSelector(state => state.userStore);
  const subject = useSelector(state => state.quizStore.fillingId);

  useEffect(() => {
    dispatch({ type: TIMER.START, payload: 30 });
    dispatch({ type: MENU.HIDE });
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <FixedPlugin
              handleImageClick={() => null}
              handleColorClick={() => null}
              bgColor={"grey"}
              bgImage={null}
              handleFixedClick={() => null}
              fixedClasses={"dropdown"}
            />
            <CustomTable
              isStarted={isStarted}
              tableHeaderColor="info"
              tableData={[]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
