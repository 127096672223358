/* eslint-disable */

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, Redirect, Route, Router, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import ListIcon from "@material-ui/icons/List";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import Quiz from "../../views/Quiz/Quiz";
import Chooser from "../../views/Chooser/Chooser";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Curriculum from "../../views/Curriculum/Curriculum";
import { useDispatch, useSelector } from "react-redux";
import { NAVIGATION } from "../../stores/actions/quizActions";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isHidden = true;

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1;
  }

  const { color, logo, image, logoText, routes } = props;
  const availableRoutes = useSelector(
    state => state.navigationStore.availableRoutes
  );
  const user = useSelector(state => state.userStore);
  const isStarted = useSelector(state => state.timerStore.isStarted);
  useEffect(() => {
    if (user.loggedIn) {
      dispatch({
        type: NAVIGATION.SET_AVAILABLE,
        payload: {
          availableRoutes: [
            {
              path: "/chooser",
              name: "Témakör választás",
              icon: ListIcon,
              component: Chooser,
              layout: "/admin"
            },
            {
              path: "/tananyag",
              name: "Tananyag",
              icon: LibraryBooks,
              component: Curriculum,
              layout: "/admin"
            },
            {
              path: "/quiz",
              name: "Kvíz kitöltés",
              icon: "content_paste",
              component: Quiz,
              layout: "/admin"
            }
          ]
        }
      });
    }
  }, [user]);

  // const readTimerState = useSelector(state => state.readTimerStore);
  const hasReadTimerEnded = useSelector(state => state.readTimerStore.hasEnded);
  const isReadTimerStarted = useSelector(
    state => state.readTimerStore.isStarted
  );
  useEffect(() => {
    if (isReadTimerStarted) {
      dispatch({
        type: NAVIGATION.SET_AVAILABLE,
        payload: {
          availableRoutes: [
            {
              path: "/tananyag",
              name: "Tananyag",
              icon: LibraryBooks,
              component: Curriculum,
              layout: "/admin"
            },
            {
              path: "/quiz",
              name: "Kvíz kitöltés",
              icon: "content_paste",
              component: Quiz,
              layout: "/admin"
            }
          ]
        }
      });
    }
    // if(readTimerState.hasEnded){
    // dispatch({
    //   type: NAVIGATION.SET_AVAILABLE,
    //   payload: {
    //     availableRoutes: [
    //       {
    //         path: "/quiz",
    //         name: "Kvíz kitöltés",
    //         icon: "content_paste",
    //         component: Quiz,
    //         layout: "/admin"
    //       }
    //     ]
    //   },
    // });
    // }
  }, [isReadTimerStarted]);

  function renderLink(prop, key, whiteFontClasses) {
    let item = (
      <ListItem button disabled={isHidden} key={key}>
        {typeof prop.icon === "string" ? (
          <Icon className={classNames(classes.itemIcon, whiteFontClasses, {})}>
            {prop.icon}
          </Icon>
        ) : (
          <prop.icon
            className={classNames(classes.itemIcon, whiteFontClasses, {})}
          />
        )}
        <ListItemText
          primary={prop.name}
          className={classNames(classes.itemText, whiteFontClasses, {})}
          disableTypography={true}
        />
      </ListItem>
    );
    let ConditionalLink = () => {
      if (!isHidden) {
        return (
          <NavLink
            to={`${prop.layout}${prop.path}`}
            disabled={!isHidden}
            style={isHidden ? { cursor: "default" } : {}}
            className={classes.item}
            activeClassName="active"
            key={key}
          >
            {item}
          </NavLink>
        );
      } else {
        return item;
      }
    };

    return ConditionalLink();
  }

  const links = (
    <List className={classes.list}>
      {availableRoutes.map((prop, key) => {
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        return renderLink(prop, key, whiteFontClasses);
      })}
      {/*<Button onChange={() => dispatch({type: USER.LOGOUT})}>*/}
      {/*  <ListItem button disabled={isStarted} >*/}
      {/*    <Icon*/}
      {/*    >*/}
      {/*      <ExitToAppIcon/>*/}
      {/*    </Icon>*/}
      {/*    <ListItemText*/}
      {/*      primary={`Kijelentkezés`}*/}
      {/*      className={classNames(classes.itemText, {})}*/}
      {/*      disableTypography={true}*/}
      {/*    />*/}
      {/*  </ListItem>*/}
      {/*</Button>*/}
    </List>
  );
  const brand = (
    <div className={classes.logo}>
      <a
        href="#"
        className={classNames(classes.logoLink, {})}
        target="_blank"
        onClick={e => e.preventDefault()}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {})
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {/*{<AdminNavbarLinks />}*/}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"right"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {})
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <div>{links}</div>
              {!(user.email === null || user.email === undefined) ? (
                <span
                  style={{
                    marginLeft: "4px",
                    color: "white",
                    fontSize: "14px"
                  }}
                >
                  e-mail: <strong>{`${user.email}`}</strong>
                </span>
              ) : null}
            </div>
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
