import React, { useEffect, useState } from "react";
import SignIn from "../../components/SignIn";

export default function Login() {
  const [inValidation] = useState(false);
  useEffect(() => {
    /*if (token !== null) {
      setInValidation(true);
      API.fetchApiEndpoint(ENDPOINTS.USERS.VALIDATE_TOKEN, {
        form: { token: token }
      })
        .then(r => {
          if (r.success) {
            dispatch({ type: USER.LOGIN, payload: { user: r.data } });
          }
          setInValidation(false);
        })
        .catch(err => console.error(err));
    }*/
  }, []);

  return <SignIn loading={inValidation} />;
}
